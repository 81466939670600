$(function () {


    /****************************************
     * 下層(News page)
     ****************************************/

    (function () {
        var container = $('#message-error');

        if($("#form-validation").length){
            $("#form-validation").validate({
                errorContainer: container,
                errorLabelContainer: $("ol", container),
                wrapper: 'li',
            });
        }

        if($(".text-field").length){
            $(".text-field").focus(function() {
                var firstname = $('#firstname').val();
                var lastname = $('#lastname').val();
                //console.log(firstname + lastname);
                if (firstname.length > 0 && lastname.length > 0) {
                    $('#fullname').val(firstname + lastname);
                }
            });
        }

    }).call(this);
});
